import Cards from './cards/Cards'

export default function Home() {

    return (
        <div className='Home'>
            <h1>כל הכרטיסים </h1>
            <h2>מערכת לניהול מתכונים</h2>
            <Cards />
        </div>
    )
}